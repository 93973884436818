import _ from 'lodash'

export const any = (object, paths, defaultValue) => {
  if (_.isString(paths)) {
    return _.get(object, paths, defaultValue)
  }
  for (let i = 0; i < paths.length; i += 1) {
    const v = _.get(object, paths[i]);
    if (v) return v
  }
  return defaultValue
};
