<template>
    <div class="app-main page-frame inner-cont">
      <router-link to="/" class="logo">
        <img ng-if="withLogo" :class="`logo-size-${logoSize}`" src="../assets/logo.svg">
      </router-link>
      <slot></slot>
    </div>
</template>

<script>
export default {
  name: 'LayoutWithDocFrame',
  props: [
    'withLogo',
    'logoSize'
  ],
  computed: {
    isGlobalLoading() {
      return this.$globalStore.state.loading
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
@import '../_mixins.scss';

h1, h2 {
  font-weight: normal;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 5px;
}

a {
  color: #42b983;
}

.page-frame {
  width: 320px;
  max-height: 483px;
  margin: auto;
  //background: url("../assets/page-frame.svg") no-repeat center center;
  background-size: contain;
  @include breakpoint(mobileonly) {
    background: none;
    width: auto;
  }
}

.inner-cont {
  padding: 20px;
  text-align: left;
  position: relative;
  @include breakpoint(mobileonly) {
    padding: 10px;
  }
}

.logo {
  display: block;
  text-align: center;
  opacity: 0.9;
}

.logo-size {
  @at-root {
    &-small {
      height: 200px;
    }
  }
}

.text-center {
  text-align: center;
}

.clickable {
  cursor: pointer;
}

</style>
