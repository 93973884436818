<template>
  <div>{{ $t('loading') }}...</div>
</template>

<script>
import messages from '../i18n'

export default {
  name: 'LoadIndicator',
  i18n: {
    messages: {
      en: {loading: "Loading"},
      bg: {loading: "Зареждане"},
      ru: {loading: "Загрузка"},
      uk: {loading: "Завантаження"},
    },
    sharedMessages: messages
  }
}
</script>

<style scoped>

</style>
