import Auth from './Auth'
import Register from './Register'
import Login from './Login'
import PasswordReset from './PasswordReset'
import NewPassword from './NewPassword'
import ValidateCredentials from './ValidateCredentials'

export {
  Auth
}

export const routes = [
  {
    path: '',
    redirect: 'login'
  },
  {
    name: 'auth.login',
    path: 'login',
    component: Login
  },
  {
    name: 'auth.register',
    path: 'signup',
    component: Register
  },
  {
    name: 'auth.pwdreset',
    path: 'pwdreset',
    component: PasswordReset
  },
  {
    name: 'auth.pwdrestore',
    path: 'pwdrestore/:token',
    meta: {otc_type: 'RESTORE_PASSWORD'},
    component: NewPassword
  },
  {
    name: 'auth.regValidation',
    path: 'register/:token',
    meta: {otc_type: 'REGISTRATION'},
    component: ValidateCredentials
  },
  {
    name: 'auth.changeEmailValidation',
    path: 'mailvalidate/:token',
    meta: {otc_type: 'EMAIL_CHANGE'},
    component: ValidateCredentials
  }
]
