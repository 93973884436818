<template>
    <div id="app">
        <div class="content">
            <load-indicator class="global-loading-indicator" v-if="isGlobalLoading"></load-indicator>
            <app-header></app-header>
            <router-view/>
        </div>
        <app-footer></app-footer>
    </div>
</template>

<script>
    import AppHeader from './components/AppHeader'
    import AppFooter from './components/AppFooter'
    import LoadIndicator from './components/LoadIndicator'

    export default {
        components: {
            LoadIndicator,
            AppFooter,
            AppHeader
        },
        name: 'app',

        computed: {
            isGlobalLoading() {
                return this.$globalStore.state.loading
            }
        }
    }
</script>

<style lang="scss">
    @import 'main';

    .global-loading-indicator {
        text-align: left;
        position: absolute;
        padding: 10px;
        top: 0;
        left: 0;
    }
</style>
