<template>
    <layout-with-doc-frame with-logo="true">
        <h1 class="header">{{ msg }}</h1>
        <div class="userlogin">
            <a href=rboard v-if="isLoggedIn" class="main-icon">
                <button class="icon key"/>
            </a>
            <router-link v-else :to="{name: 'auth.login'}" class="main-icon">
                <button class="icon pad"/>
            </router-link>
        </div>
        <section class="menu" hidden>
            <button class="licenses" title="Licenses"/>
            <button class="downloads" title="Downloads"/>
        </section>
    </layout-with-doc-frame>
</template>

<script>
import LayoutWithDocFrame from './LayoutWithDocFrame'
import {RETAILER_BOARD_URL} from '../router'

export default {
    name: 'AppMain',
    components: {
        LayoutWithDocFrame
    },

    data: function () {
        return {
            msg: 'DKDHUB',
            rboard: RETAILER_BOARD_URL
        }
    },

    computed: {
        isLoggedIn() {
            return this.$globalStore.state.isLoggedIn
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h1 {
    margin: 20px 0;
    text-align: center;
}

h2 {
    margin: 30px 0 20px 0;
}

.app-main {
    max-height: 720px;
    //background-color: #fba158;

    .main-icon {
        display: block;

        .icon {
            width: 72px;
            height: 72px;
            border: 0;
        }

        .pad {
            background: url("../assets/pad.svg") no-repeat;
        }

        .key {
            cursor: pointer;
            background: url("../assets/key.svg") no-repeat;
        }
    }

    .userlogin {
        display: block;
        text-align: center;
    }

    .menu {
        width: inherit;
        height: 36px;
        border: 0;
        margin-top: 20px;
        padding-top: 10px;
        padding-bottom: 10px;
        text-align: center;
        background-color: rgba(#fff, 0.5);
        border-radius: 10px;
        box-shadow: 0 0 5px 2px rgba(#bbb, 0.2);

        button {
            width: 36px;
            height: 36px;
            border: 0;
            margin: 0 6px 0 6px;

            mask-repeat: no-repeat;
            transition: background-color 300ms;
            background-color: rgba(#000,0.5);
            &:hover{
                background-color: rgba(#000,0.8);;
            }
        }

        .downloads {
            mask-image: url("../assets/downloads.svg");
        }

        .licenses {
            mask-image: url("../assets/licenses.svg");

        }
    }
}

</style>
