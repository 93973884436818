import axios from 'axios';

const tbtAPIBase = 'https://api.tbt-post.net/api/v1/';

export const lowerCase = (str) => str.toString().toLowerCase();

export function callTBTApi(url,
                           data = {},
                           method = 'get',
                           timeout = 30000,
                           headers = {},
                           ops = {}) {

    const _method = (lowerCase(method));
    const _headers = {
        ...(_method === 'post' || _method === 'put') ?
            {'Content-Type': 'application/json'} :
            {'Content-Type': 'text/html'},
        ...headers,
    };

    const payload = (_method === 'get' || _method === 'head' || _method === 'delete') ?
        {params: data} :
        {data: data};

    return axios.request({
        baseURL: tbtAPIBase,
        url: url,
        method: _method,
        timeout: timeout,
        headers: _headers,
        // responseType: 'json', // the default is 'json'
        validateStatus: function (status) {
            return status >= 200 && status < 600;
        },
        withCredentials: false,
        ...ops,
        ...payload
    });

}
