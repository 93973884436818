<template>
  <layout-with-doc-frame :with-logo="true" logo-size="small">
    <router-view :global-state="state" :set-global-state="setState"/>
    <links class="auth-links"></links>
  </layout-with-doc-frame>
</template>

<script>
  import LayoutWithDocFrame from '../LayoutWithDocFrame'
  import Links from './Links'

  export default {
    name: 'Auth',
    components: {
      LayoutWithDocFrame,
      Links
    },

    data () {
      return {
        state: {}
      }
    },

    computed: {
      isLoggedIn () {
        return this.$globalStore.state.isLoggedIn
      }
    },

    methods: {
      setState (newState = {}) {
        this.state = {...this.state, ...newState}
      }
    }
  }
</script>

<style lang="scss" scoped>
  .auth-links {
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 20px;
    text-align: right;
  }

</style>

<style lang="scss">
  $errorColor: #F51C2E;

  .form {
    margin: 10px auto;
    max-width: 300px;
    padding: 0px 12px 10px 20px;

    li {
      padding: 0;
      display: block;
      list-style: none;
      margin: 0;
      &.without-validation {
        margin-bottom: 20px;
      }
    }

    input[type=text],
    input[type=email],
    input[type=password] {
      font-size: 14px;
      width: 100%;
      box-sizing: border-box;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      border: 1px solid #BEBEBE;
      padding: 7px;
      margin: 0;
      transition: all 0.30s ease-in-out;
      outline: none;

      $bgColor: #E9C9AF;
      &:focus {
        box-shadow: 0 0 8px $bgColor;
        border: 1px solid $bgColor;
      }
      &.invalid {
        $color: lighten($errorColor, 30%);
        background: lighten($color, 15%);
        border-color: $color;
        &:focus {
          box-shadow: 0 0 8px $color;
          border: 1px solid $color;
        }
      }
    }

    .field-long {
      width: 100%;
    }

    .required {
      color: $errorColor;
    }

    @at-root {

      &-submit-error {
        min-height: 30px;
        color: $errorColor;
        text-align: center;;
      }

      &__no-space-top {
        margin-top: 0;
      }

      &-field {
        @at-root {
          &-error {
            color: $errorColor;
            font-size: 13px;
          }
        }
        @at-root {
          &-errors {
            min-height: 20px;
          }
        }
      }
    }
  }


</style>



