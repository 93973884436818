<template>
  <transition name="z-modal-container">
    <div class="modal-mask" @click="close()">
      <div class="modal-wrapper">
        <div class="modal-container" @click.stop>
          <div v-show="sent" style="padding: 20px 0; font-weight: bold;">{{ $t('thankYou') }}</div>
          <div class="modal-header">
            <slot name="header">
              <h3>{{ formHeader || 'Contact us' }}</h3>
            </slot>
          </div>

          <div class="modal-body">
            <slot name="body">
              <label class="form-label">
                {{ titleHeader || 'Title' }}
                <input v-model="title" class="form-control">
              </label>
              <label class="form-label">
                {{ titleBody || 'Body' }}
                <textarea v-model="body" rows="5" class="form-control">
                </textarea>
              </label>
            </slot>
          </div>

          <div class="modal-footer text-right">
            <slot name="footer">
              <button class="modal-default-button" @click="commitMessage()">
                {{ titleSend || 'Send!' }}
              </button>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import {callTBTApi} from "../shared/tbt";
import messages from "@/i18n";

export default {
  name: 'z-modal-container',
  i18n: {
    messages: {
      en: {thankYou: "Thank you, request sent!"},
      bg: {thankYou: "Благодаря, заявката е изпратена!"},
      ru: {thankYou: "Спасибо, запрос отправлен!"},
      uk: {thankYou: "Дякуємо, запрос відправлено!"},
    },
    sharedMessages: messages
  },
  data() {
    return {
      // eslint-disable-next-line vue/no-reserved-keys
      _onScreen: false,
      title: this.title,
      header: this.header,
      body: this.body,
      footer: this.footer,
      sent: false
    }
  },
  props: [
    'title-header',
    'title-body',
    'title-send',
    'form-header'
  ],
  methods: {
    commitMessage: async function () {
      // Some save logic goes here...
      // eslint-disable-next-line no-console
      console.debug('sending >>', this.title, this.body);
      await this._pushMessage()
      this.sent = true;
      setTimeout(() => this.close(), 1000);
    },
    close: function () {
      this._onScreen = false;
      this.$emit('close');
    },

    _pushMessage() {

      callTBTApi('_landings/backlink',
          {
            name: this.title,
            message: this.body,
            contact: 'anonymous@dkdhub.com',
            company: 'DKDHUB-GW'
          },
          'post');

    }
  },
  mounted: function () {
    this._onScreen = true;
    document.addEventListener('keydown', (e) => {
      // noinspection JSDeprecatedSymbols
      if (this._onScreen && e.keyCode === 27) {
        // eslint-disable-next-line no-console
        console.debug('Form Discarded');
        this.close();
      }
    })
  }
}
</script>

<style scoped lang="scss">
* {
  box-sizing: border-box;
}

textarea {
  resize: vertical
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  transition: opacity .3s ease;
}

.modal-container {
  width: 400px;
  margin: 40px auto 0;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
  transition: all .3s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
}

.text-right {
  text-align: right;
}

.form-label {
  display: block;
  margin-bottom: 1em;
}

.form-label > .form-control {
  margin-top: 0.5em;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.5em 1em;
  line-height: 1.5;
  border: 1px solid #ddd;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.modal-default-button {
  padding: 10px 20px;
}
</style>
