<template>
  <div>
    <div class="form-submit-error">
      <div v-if="error">{{ error }}</div>
    </div>
    <vue-form :state="formstate" class="form form__no-space-top" @submit.prevent="onSubmit">
      <ul>
        <validate tag="li">
          <label>Email <span class="required">*</span></label>
          <input v-model="model.username" type="email" class="" :server-errors="{name: 'email', errors}" required
                 name="username"/>

          <field-messages class="form-field-errors" name="username" show="$touched || $submitted">
            <div class="form-field-error" slot="required">{{ $t('requiredField') }}</div>
            <div class="form-field-error" slot="email">{{ $t('wrongMail') }}</div>
            <div class="form-field-error" slot="server-errors">{{ serverErrorMessage('email') }}</div>
          </field-messages>
        </validate>

        <validate tag="li" class="without-validation">
          <label>{{ $t('pass') }}</label>
          <input v-model="model.password" type="password" name="password"/>
        </validate>

        <li>
          <SubmitButton style="float: right" :loading="loading" :caption="$t('login')"/>
        </li>
      </ul>
    </vue-form>
  </div>
</template>

<script>
import _ from 'lodash'
import FormWihServerErrorsMixin from './FormWihServerErrorsMixin'
import SubmitButton from './SubmitButton'
import {fetchApi} from '../../shared/api'
import messages from "@/i18n";

export default {
  name: 'Login',
  i18n: {
    messages: {
      en: {
        pass: "Password",
        requiredField: "This is a required field",
        wrongMail: "Wrong email",
        login: "Login"
      },
      bg: {
        pass: "Парола",
        requiredField: "Това е задължително поле",
        wrongMail: "Грешен имеил",
        login: "Вход"
      },
      ru: {
        pass: "Пароль",
        requiredField: "Это поле обязательно для заполнения",
        wrongMail: "Ошибочный email",
        login: "Вход"
      },
      uk: {
        pass: "Пароль",
        requiredField: "Це поле обовʼязкове до заповнення",
        wrongMail: "Помилковий email",
        login: "Вхід"
      },
    },
    sharedMessages: messages
  },
  mixins: [
    FormWihServerErrorsMixin
  ],

  components: {
    SubmitButton
  },

  props: [
    'setGlobalState',
    'globalState'
  ],

  data() {
    return {
      formstate: {},
      model: {
        username: this.globalState.username || '',
        password: ''
      },
      error: null,
      loading: false
    }
  },

  watch: {
    'model.username'(val) {
      this.setGlobalState({username: val})
    }
  },

  methods: {
    async onSubmit() {
      await this.resetErrors()

      if (this.formstate.$invalid) {
        return
      }
      this.loading = true
      try {
        await fetchApi('auth/login', this.model, 'post')
        const next = _.get(this.$route, 'query.next')
        if (next) {
          window.location = next
        }
        this.$router.push({name: 'loggedIn'})
      } catch (e) {
        this.prepareErrors(e)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped>

</style>
