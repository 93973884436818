<template>
    <div>
        <LoadIndicator v-if="loading"/>
        <div v-else>
            <Error v-if="error" :message="error"/>
            <Success v-else :message="$t('emailOK')"/>
        </div>
    </div>
</template>

<script>
import {errorMessage, fetchApi} from '../../shared/api'
import _ from 'lodash'
import LoadIndicator from '../LoadIndicator'
import Success from './Success'
import Error from "./Error"

export const validate = (otcType, token) => {
    return fetchApi('otc/validate', {otc_type: otcType, otc: token})
};

export const validationMixin = {
    async mounted() {
        this.loading = true;
        try {
            const otcType = this.otc.otc_type;
            const otc = this.otc.token;
            await validate(otcType, otc)
        } catch (e) {
            if (e.status === 404) {
                await this.$router.replace('/404')
            }
            this.error = errorMessage(e)
        } finally {
            this.loading = false
        }
        // setTimeout(() =>
        //         this.$router.push({path: '/'}),
        //     // window.location.href = RETAILER_BOARD_URL,
        //     7000);
    },

    computed: {
        otc() {
            return {
                token: this.$route.params.token,
                otc_type: _.get(this.$route, 'meta.otc_type')
            }
        }
    }
};

export default {
    name: 'ValidateCredentials',
    components: {Success, Error, LoadIndicator},
    params: [
        'token'
    ],
    i18n: {
        en: {emailOK: "Email confirmed"},
        bg: {emailOK: "Имейлът е потвърден"},
        ru: {emailOK: "Email подтвержден"},
        uk: {emailOK: "Email підтверджено"}
    },
    mixins: [validationMixin],

    data() {
        return {
            loading: true,
            error: false
        }
    }
}
</script>

<style scoped>
</style>
