import axios from 'axios'
import {any} from './utils'

const DEFAULT_TIMEOUT = 30000;

export const APIBase =
    process.env.NODE_ENV === 'development'
        ? 'https://dev-gw-ua.dkdhub.net/api/v1/'
        : '/api/v1/';

axios.defaults.baseURL = APIBase;

axios.interceptors.response.use(
    response => ({status: response.status, json: response.data}),
    (error) => {
        let result = {status: 500, json: {}};
        if (error.response) {
            result = {
                status: error.response.status,
                json: error.response.data
            }
        }
        return Promise.reject(result)
    }
);

export function fetchApi(endpoint, data, method = 'post', timeout = DEFAULT_TIMEOUT) {
    const url = endpoint;
    let headers = {};
    let params = {};
    if (method === 'post' || method === 'put') {
        headers = {
            'Content-Type': 'application/json'
        }
    } else {
        headers = {
            'Content-Type': 'text/html'
        };
        params = data
    }

    const options = {
        method,
        data,
        headers,
        params,
        url,
        withCredentials: true,
        timeout
    };
    return axios(options)
}

export const errorMessage = requestError => any(requestError, ['messsage', 'json.message', 'error']);
