import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './shared/store'
import VueI18n from 'vue-i18n';
import './i18n'
import i18n from "@/i18n";

Vue.use(store);
Vue.use(VueI18n);

Vue.config.productionTip = false;

export const defaultAppLocale = 'en'

function getBrowserLocale(options = {}) {
    const defaultOptions = {countryCodeOnly: false}

    const opt = {...defaultOptions, ...options}

    const navigatorLocale =
        navigator.languages !== undefined
            ? navigator.languages[0]
            : navigator.language

    if (!navigatorLocale) {
        return undefined
    }

    return opt.countryCodeOnly
        ? navigatorLocale.trim().split(/[-_]/)[0]
        : navigatorLocale.trim()
}

export const messages = i18n

function bootstrapLocale() {
    const browserLocale = getBrowserLocale({countryCodeOnly: true})

    return (browserLocale && messages[browserLocale])
        ? browserLocale
        : defaultAppLocale
}

new Vue({
    el: '#app',
    router,
    render: function (h) {
        return h(App)
    },
    gStore: store,
    i18n: new VueI18n({
        locale: bootstrapLocale(),
        fallbackLocale: defaultAppLocale,
        silentFallbackWarn: true,
        messages
    }),
    template: '<App/>',
    components: {App}
});
