<template>
  <header>
  </header>
</template>

<script>
  export default {
    name: 'app-header'
  }
</script>

<style scoped>

</style>
