<template>
  <div>
    <router-link :class="{'links-item': true, 'smaller': $i18n.locale !== 'en'}"
                 v-for="item in links"
                 :key="item.to"
                 :to="{name: item.to}">
      {{ item.text }}
    </router-link>
  </div>
</template>

<script>
import _ from 'lodash'
import messages from "@/i18n";

export default {
  name: 'Links',
  i18n: {
    messages: {
      en: {signUp: "Sign up", signIn: "Sign in", restorePw: "Forgot password?"},
      bg: {signUp: "Регистрирай се", signIn: "Влезте с имейл", restorePw: "Забравена парола?"},
      ru: {signUp: "Регистрация", signIn: "Войти с email", restorePw: "Забыли пароль?"},
      uk: {signUp: "Реєстрація", signIn: "Увійти з email", restorePw: "Забули пароль?"},
    },
    sharedMessages: messages
  },
  data() {
    return {}
  },

  computed: {
    items() {
      return [
        {
          to: 'auth.register',
          text: this.$t('signUp')
        },
        {
          to: 'auth.login',
          text: this.$t('signIn')
        },
        {
          to: 'auth.pwdreset',
          text: this.$t('restorePw')
        }
      ]
    },
    links() {
      return _.filter(this.items, i => i.to !== this.$route.name)
    }
  }
}
</script>

<style lang="scss" scoped>
.links-item {
  margin-right: 10px;
  text-decoration: none;
}

.smaller {
  font-size: small;
}
</style>
