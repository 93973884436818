<template>
  <layout-with-doc-frame with-logo="true">
    <h1 class="text-center">404</h1>
    <p class="text-center">{{ $t('notFound') }}</p>
    <div class="text-center">
      <a href="/#/">{{ $t('goHome') }}</a>
    </div>
  </layout-with-doc-frame>
</template>

<script>
import LayoutWithDocFrame from './LayoutWithDocFrame'
import messages from "@/i18n";

export default {
  name: 'PageNotFound',
  components: {
    LayoutWithDocFrame
  },
  i18n: {
    messages: {
      en: {notFound: "Page not found", goHome: "Take me home"},
      bg: {notFound: "Страницата не е намерена", goHome: "Заведи ме вкъщи"},
      ru: {notFound: "Страница не найдена", goHome: "Отведите меня домой"},
      uk: {notFound: "Сторінку не знайдено", goHome: "Заберіть мене додому"},
    },
    sharedMessages: messages
  }
}
</script>

<style scoped>

</style>
