<template>
    <div v-if="!success">
        <div class="form-submit-error">
            <div v-if="error">{{ error }}</div>
        </div>

        <vue-form :state="formstate" class="form form__no-space-top" @submit.prevent="onSubmit">
            <ul>
                <validate tag="li">
                    <label>Email <span class="required">*</span></label>
                    <input v-model="model.email" type="email" required name="email"/>
                    <field-messages class="form-field-errors" name="email" show="$touched || $submitted">
                        <div class="form-field-error" slot="required">{{ $t('requiredField') }}</div>
                        <div class="form-field-error" slot="email">{{ $t('wrongMail') }}</div>
                    </field-messages>
                </validate>
                <li>
                    <submit-button style="float: right" :loading="loading" :caption="$t('passReset')"></submit-button>
                </li>
            </ul>
        </vue-form>
    </div>

    <Success v-else :message="$t('note')" class="padded">
        <p class="text-center">{{ $t('message') }}</p>
    </Success>

</template>

<script>
import FormWihServerErrorsMixin from './FormWihServerErrorsMixin'
import SubmitButton from './SubmitButton'
import Success from './Success'
import {fetchApi} from '../../shared/api'
import messages from "@/i18n";

export default {
    name: 'PasswordReset',
    i18n: {
        messages: {
            en: {
                pass: "Password",
                requiredField: "This is a required field",
                wrongMail: "Wrong email",
                passReset: "Reset password",
                note: "Email sent!",
                message: "Follow instructions in email to reset the password",
            },
            bg: {
                pass: "Парола",
                requiredField: "Това е задължително поле",
                wrongMail: "Грешен имеил",
                passReset: "Възстановяване на паролата",
                note: "Изпратено имейл съобщение!",
                message: "Следвайте инструкциите в имейла, за да нулирате паролата",
            },
            ru: {
                pass: "Пароль",
                requiredField: "Это поле обязательно для заполнения",
                wrongMail: "Ошибочный email",
                passReset: "Восстановление пароля",
                note: "Сообщение отправлено!",
                message: "Следуйте инструкциям в электронном письме, чтобы сбросить пароль",
            },
            uk: {
                pass: "Пароль",
                requiredField: "Це поле обовʼязкове до заповнення",
                wrongMail: "Помилковий email",
                passReset: "Відновлення паролю",
                note: "Повідомлення відправлено!",
                message: "Дотримуйтесь інструкцій в електронній пошті, щоб скинути пароль",
            },
        },
        sharedMessages: messages
    },
    props: [
        'globalState',
        'setGlobalState'
    ],

    components: {
        SubmitButton,
        Success
    },

    mixins: [
        FormWihServerErrorsMixin
    ],

    data() {
        return {
            formstate: {},
            model: {
                email: (this.globalState) ? this.globalState.username : '',
                password: '',
                otc_type: 'RESTORE_PASSWORD'
            },
            error: null,
            loading: false,
            success: false
        }
    },

    watch: {
        'model.email'(val) {
            this.setGlobalState({username: val})
        }
    },

    methods: {
        async onSubmit() {
            await this.resetErrors()

            if (this.formstate.$invalid) {
                return
            }
            this.loading = true
            try {
                await fetchApi('otc/new', this.model, 'post')
                this.success = true
            } catch (e) {
                this.prepareErrors(e)
            } finally {
                this.loading = false
            }
        }
    }

}
</script>

<style scoped lang="scss">
.padded {
    flex: auto;
    margin-bottom: 10px;
}
</style>
