<template>
    <div class="success-message">
        <div v-if="message" class="success-message-title">{{ message }}</div>
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: 'Success',
    props: ['message']
}
</script>

<style lang="scss" scoped>
.success-message {
    padding-top: 30px;
    padding-bottom: 30px;
    @at-root {
        &-title {
            font-size: 18px;
            text-align: center;
            font-weight: bold;
            margin-bottom: 30px;
        }
    }
}
</style>
