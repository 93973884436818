<template>
  <layout :with-logo="true">
    <div v-if="error"><p style="color: red">{{ error }}</p></div>
    <div v-if="loading">{{ $t('loading') }}</div>
    <success v-else message="You are logged in!">
      <p class="text-center"> {{ $t('welcome') }} <a href=rboard>DKDHUB</a></p>
      <div class="text-center logout">
        <a class="clickable" @click="logout">{{ $t('logout') }}</a>
      </div>
    </success>
  </layout>
</template>

<script>
import {errorMessage, fetchApi} from '@/shared/api'
import Layout from '../LayoutWithDocFrame'
import Success from './Success'
import {RETAILER_BOARD_URL} from '@/router'
import messages from "@/i18n";
import {v4} from "uuid";

export default {
  name: 'LoggedIn',
  components: {Success, Layout},
  i18n: {
    messages: {
      en: {loading: "Loading", welcome: "Welcome to", logout: "Logout"},
      bg: {loading: "Зареждане", welcome: "Добре дошли в", logout: "Изход"},
      ru: {loading: "Загрузка", welcome: "Добро пожаловать в", logout: "Выход"},
      uk: {loading: "Завантаження", welcome: "Вітаємо у", logout: "Вихід"},
    },
    sharedMessages: messages
  },
  data() {
    return {
      loading: false,
      error: null,
      rboard: RETAILER_BOARD_URL
    }
  },

  async mounted() {
    this.loading = true
    const isLoggedIn = await this.$globalStore.checkIfLoggedIn()
    this.loading = false
    if (!isLoggedIn) {
      localStorage.removeItem('last-auth-time');
      sessionStorage.removeItem('session-id');
      this.$router.replace({name: 'auth.login'})
    } else {
      localStorage.setItem('last-auth-time', (new Date()).valueOf().toString());
      sessionStorage.setItem('session-id', v4())
    }
    setTimeout(() =>
            this.$router.push({path: '/'}),
        // window.location.href = RETAILER_BOARD_URL,
        7000);
  },

  methods: {
    async logout() {
      this.loading = true
      this.error = null
      try {
        await fetchApi('auth/logout/', null, 'get')
        await this.$globalStore.checkIfLoggedIn()
        this.$router.push({name: 'auth.login'})
      } catch (e) {
        this.error = errorMessage(e)
      } finally {
        this.loading = false
      }
    },

  },

}
</script>

<style lang="scss" scoped>
.logout {
  margin-top: 20px;
}
</style>
