import {fetchApi} from './api'
import router from '../router'

const store = {
    state: {
        isLoggedIn: false,
        initialized: false,
        loading: false,
        profile: null
    },

    setLoggedIn(isLoggedIn) {
        this.state.isLoggedIn = isLoggedIn
    },

    checkIfLoggedIn: async function () {
        let result = false;
        try {
            await fetchApi('user/profile', null, 'head');
            result = true
        } catch (e) {
          // eslint-disable-next-line no-empty
        }

        store.setLoggedIn(result);
        return result
    },

  // eslint-disable-next-line no-unused-vars
    async init(force) {
        if (!this.force && this.state.initialized) return;

        this.state.loading = true;

        try {
            await Promise.all([this.checkIfLoggedIn()])
        } catch (e) {
            this.state.initialized = false
        } finally {
            this.state.loading = false
        }
    },

    async getProfile() {
        try {
            const profile = await fetchApi('user/profile', null, 'get');
            this.state.profile = profile.json
        } catch (e) {
            await router.push({name: 'auth.login'})
        } finally {
            this.loading = false
        }
    },

  // eslint-disable-next-line no-unused-vars
    install(Vue, options = {}) {
        const self = this;
        this._store = new Vue({
            data: {
                globalState: self.state
            }
        });
        Vue.prototype.$globalStore = this
    }
};

store.init();

export default store
