import _ from 'lodash'
import VueForm from 'vue-form'
import {errorMessage} from '../../shared/api'

const FormWihServerErrorsMixin = {
  data () {
    return {
      errors: {},
      error: null
    }
  },

  mixins: [
    new VueForm({
      validators: {
        'server-errors': function (value, attrValue = {}) {
          const {name, errors} = attrValue;
          return !_.get(errors, name);
        }
      }
    })
  ],

  methods: {
    prepareErrors (e) {
      let unexpected = 'Unexpected error. Please, try again later.';
      const errors = _.get(e, 'json.errors');
      if (errors) {
        this.errors = errors;
        unexpected = '';
      }
      this.error = errorMessage(e) || unexpected
    },

    resetErrors () {
      this.error = null;
      this.errors = {};
      return this.$nextTick();
    },

    serverErrorMessage (name) {
      return _.get(this.errors, `${name}[0]`)
    }
  }
};

export default FormWihServerErrorsMixin
