<template>
  <div v-if="!success">
    <div class="form-submit-error">
      <div v-if="error">{{error}}</div>
    </div>
    <vue-form :state="formstate" class="form form__no-space-top" @submit.prevent="onSubmit">
      <ul>
        <validate tag="li">
          <label>{{ $t('pass') }} <span class="required">*</span></label>
          <input v-model="model.password" type="password" :server-errors="{name: 'new_password', errors}" required
                 minlength="6" name="password"/>
          <field-messages class="form-field-errors" name="password" show="$touched || $submitted">
            <div class="form-field-error" slot="required">{{ $t('requiredField') }}</div>
            <div class="form-field-error" slot="minlength">6 {{ $t('charsMin') }}</div>
            <div class="form-field-error" slot="server-errors">{{serverErrorMessage('new_password')}}</div>
          </field-messages>
        </validate>

        <validate tag="li" :custom="{equal: this.model.password === this.model.password2}">
          <label>{{ $t('pass2T') }}<span class="required">*</span></label>
          <input v-model="model.password2" type="password" required name="password2"/>
          <field-messages class="form-field-errors" name="password2" show="$touched || $submitted">
            <div class="form-field-error" slot="required">{{ $t('requiredField') }}</div>
            <div class="form-field-error" slot="equal">{{ $t('passMM') }}</div>
          </field-messages>
        </validate>

        <li>
          <submit-button style="float: right" :loading="loading" :caption="$t('change')"></submit-button>
        </li>
      </ul>
    </vue-form>
  </div>

  <Success v-else :message="$t('changed')">
    <p class="text-center">{{ $t('nowUcan') }}
      <router-link :to="{name: 'auth.login'}">{{ $t('login') }}</router-link>
      {{ $t('withNewP') }}
    </p>
  </Success>
</template>

<script>
  import SubmitButton from './SubmitButton'
  import Success from './Success'
  import {fetchApi} from '../../shared/api'
  import {validationMixin} from './ValidateCredentials'
  import FormWihServerErrorsMixin from './FormWihServerErrorsMixin'
  import messages from "@/i18n";

  export default {
    name: 'NewPassword',
    i18n: {
      messages: {
        en: {
          pass: "Password",
          requiredField: "This is a required field",
          passMM: "Passwords must match",
          pass2T: "Password again",
          charsMin: "characters minimum",
          nowUcan: "Now you can",
          login: "sign in",
          withNewP: "using your new password",
          change: "Change password",
          changed: "Password changed!"
        },
        bg: {
          pass: "Парола",
          requiredField: "Това е задължително поле",
          passMM: "Паролите трябва да съвпадат",
          pass2T: "Паролата отново",
          charsMin: "знака минимум",
          nowUcan: "Сега можете да",
          login: "влезете",
          withNewP: "с новата си парола",
          change: "Промяна на паролата",
          changed: "Паролата е променена!"
        },
        ru: {
          pass: "Пароль",
          requiredField: "Это поле обязательно для заполнения",
          passMM: "Пароли должны совпадать",
          pass2T: "Пароль еще раз",
          charsMin: "знаков минимум",
          nowUcan: "Теперь вы можете",
          login: "войти",
          withNewP: "используя новый пароль",
          change: "Сменить пароль",
          changed: "Пароль изменен!"
        },
        uk: {
          pass: "Пароль",
          requiredField: "Це поле обовʼязкове до заповнення",
          passMM: "Паролі повинні співпадати",
          pass2T: "Пароль ще раз",
          charsMin: "знаків мінімум",
          nowUcan: "Тепер ви можете",
          login: "увійти",
          withNewP: "використовуючи новий пароль",
          change: "Змінити пароль",
          changed: "Пароль змінено!"
        },
      },
      sharedMessages: messages
    },
    components: {
      SubmitButton,
      Success
    },

    mixins: [
      FormWihServerErrorsMixin,
      validationMixin
    ],

    data () {
      return {
        formstate: {},
        model: {
          password: '',
          password2: ''
        },
        error: false,
        loading: false,
        success: false
      }
    },

    methods: {
      async onSubmit () {
        await this.resetErrors()
        if (this.formstate.$invalid) {
          return
        }
        this.loading = true
        try {
          await fetchApi('user/profile/password/', {new_password: this.model.password, otc: this.otc.token}, 'post')
          this.success = true
        } catch (e) {
          this.prepareErrors(e)
        } finally {
          this.loading = false
        }
      }
    }
  }
</script>

<style scoped>

</style>
