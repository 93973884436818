<template>
  <footer>
    <div class="container">
      <div class="contacts">
        <div class="title">{{ $t('copyright') }}:</div>
        <div class="contact-items">
          <div class="text-items">
            <p>© 2018-{{ year }} <a href="http://updcon.net" target="_blank">UPD Consulting</a></p>
            <p>© 2017-{{ year }} <a href="http://ff-lab.info" target="_blank">Fern Flower Lab</a></p>
          </div>
        </div>
      </div>
      <div class="contacts">
        <div class="title">{{ $t('address') }}:</div>
        <div class="contact-items">
          <div class="text-items">
            <p>{{ $t('addressL1') }}</p>
            <p>{{ $t('addressL2') }}</p>
          </div>
        </div>
      </div>
      <div class="contacts">
        <div class="title">{{ $t('contactUs') }}:</div>
        <div class="contact-items">
          <a class="icon call" href="tel:+35952919535"/>
          <a class="icon telegram" href="https://t.me/dkdhub"/>
        </div>
      </div>
      <div class="contacts">
        <div>
          <i class="fa fa-globe float-left"/>
          <div class="lang-selector">
            <v-select :options="options"
                      append-to-body
                      :clearable="false"
                      v-model="lang"
                      :value="lang"
                      :calculate-position="withPopper">
              <template slot="option" slot-scope="option">
                <i :class="`${option.icon} list-icon`"/> {{ option.title }}
              </template>
            </v-select>
          </div>
          <div class="contact-items">
            <button class="icon mail" id="show-modal" @click="showZModal = true"/>
            <a class="icon li-prod"
               href="https://dkd.pw/updc-li-product-page"
               target="_blank"/>
          </div>
        </div>
        <z-modal-container v-if="showZModal" @close="showZModal = false"
                           :form-header="$t('mailTitle')"
                           :title-header="$t('mailHeader')"
                           :title-body="$t('mailMessage')"
                           :title-send="$t('mailSend')"/>
      </div>
    </div>
  </footer>
</template>

<script>
import ZModalContainer from '../components/ZModalForm'
import externalResources from '../mixins/ext-loader'
import vSelect from 'vue-select'
import {createPopper} from '@popperjs/core'
import messages from '../i18n'

const langIcon = 'fa fa-globe';

export default {
  components: {
    ZModalContainer,
    vSelect
  },
  name: 'app-footer',
  mixins: [externalResources],
  external: [],
  i18n: {
    messages: {
      en: {
        contactUs: "Contact us",
        address: "Address",
        addressL1: "9300 Bulgaria, Dobrich city",
        addressL2: "2A Nezavisimost Str., floor 3",
        copyright: "Copyright",
        mailTitle: "Request a quote",
        mailHeader: "Your business description",
        mailMessage: "Message",
        mailSend: "Request"
      },
      bg: {
        contactUs: "Свържете се с нас",
        address: "Адрес",
        addressL1: "9300 България, гр. Добрич",
        addressL2: "ул. Независимост, 2A, ет. 3",
        copyright: "Авторско право",
        mailTitle: "Поискайте оферта",
        mailHeader: "Описание на бизнеса ви",
        mailMessage: "Съобщение",
        mailSend: "Да изпрати"
      },
      ru: {
        contactUs: "Связаться с нами",
        address: "Адрес",
        copyright: "Авторское право",
        mailTitle: "Отправить запрос",
        mailHeader: "Описание Вашего бизнеса",
        mailMessage: "Сообщение",
        mailSend: "Отправить"
      },
      uk: {
        contactUs: "Звʼязатись з нами",
        address: "Адреса",
        copyright: "Авторське право",
        mailTitle: "Відправити запрос",
        mailHeader: "Опис вашого бізнесу",
        mailMessage: "Повідомлення",
        mailSend: "Відправити"
      }
    },
    sharedMessages: messages
  },
  data() {
    return {
      showZModal: false,
      year: new Date().getUTCFullYear(),

      lang: null,
      options: [],
      placement: 'top'
    }
  },
  methods: {
    withPopper(dropdownList, component, {width}) {
      dropdownList.style.width = width

      const popper = createPopper(component.$refs.toggle, dropdownList, {
        placement: this.placement,
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, -1],
            },
          },
          {
            name: 'toggleClass',
            enabled: true,
            phase: 'write',
            fn({state}) {
              component.$el.classList.toggle(
                  'drop-up',
                  state.placement === 'top'
              )
            },
          },
        ],
      })

      return () => popper.destroy()
    }
  },

  mounted() {
    this.lang = {
      label: this.$i18n.locale,
      title: this.$t('lang'),
      icon: langIcon,
    }

    const langs = Object.keys(this.$i18n.messages || {})

    this.options = []

    langs.forEach(kw =>
        this.options.push({label: kw, title: this.$i18n.messages[kw].lang, icon: langIcon}))
  },

  watch: {
    lang(val) {
      console.warn('Locale:', val)
      // this.$i18n.locale = val.label
      this.$root.$i18n.locale = val.label
    }
  }
}
</script>

<style src="../styles/fa/font-awesome.scss" lang="scss"/>

<style lang="scss">
$white_color: #fff;
$height: 150px;
$container-width: 1140px;
$color: #989da3;

.list-icon {
  padding: 2px;
  margin-right: 5px;
}

.float-left {
  float: left;
  padding-top: 9px;
  margin-left: -16px;
  font-size: 20px;
  text-align: left;
  font-weight: bold;
}

.lang-selector {
  max-width: 90%;
  align-content: flex-end;
  font-weight: bold;
  color: #BEBEBE;
  margin-bottom: 7px;
  padding: 5px;
  flex: content-box;

  .vs__dropdown-toggle {
    border: 0;
  }
}

@import '~vue-select/dist/vue-select.css';

.vs__dropdown-option {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
}

.vs__dropdown-option--highlight {
  background: #fba158;
  color: #fff;
}

.vs__dropdown-menu {
  box-shadow: 0 10px 25px 0 rgba(0, 0, 0, .25);
  border: 1px dashed rgba(60, 60, 60, .26);
  border-radius: 4px;
  text-align: left;
  background: rgba(#fff, 0.9)
}

.vs__selected {
  color: $color;
  font-size: 14px;
  text-transform: uppercase;
  font-variant: small-caps;
}

footer {
  height: $height;
  background: #3b3e43;
  color: $color;

  button, a.icon {
    outline: none;
  }

  .container {
    display: flex;
  }

  .copyrights {
    font-size: 12px;
    height: 100%;
    line-height: $height;
    padding-left: 20px;
  }

  .contacts {
    justify-self: start;
    font-size: 14px;
    align-self: center;
    padding-left: 50px;
    margin-left: 50px;
    font-weight: 100;
    border-left: 1px dashed darken($color, 20%);

    &:first-child {
      border-left: none;
      margin-left: 0;
    }

    .title {
      padding-top: 10px;
      margin-bottom: 20px;
      text-align: left;
      font-weight: bold;
    }

    .text-items {
      a {
        text-decoration: none;
      }

      height: 44px;
    }

    .contact-items {
      display: flex;

      .icon {
        width: 44px;
        height: 44px;
        border: 0;
        margin-right: 10px;
      }

      .call {
        background: url("../assets/call.svg") no-repeat;
      }

      .mail {
        background: url("../assets/mail.svg") no-repeat;
      }

      .li-prod {
        background: url("../assets/updc-li-product.svg") no-repeat;
      }

      .telegram {
        background: url("../assets/telegram.svg") no-repeat;
      }

      .whatsapp {
        background: url("../assets/whatsapp.svg") no-repeat;
      }

      .skype {
        background: url("../assets/skype.svg") no-repeat;
      }

      .price {
        background: url("../assets/price.svg") no-repeat;
      }

    }

    .v-select {
      font-family: 'Avenir', Helvetica, Arial, sans-serif !important;
    }
  }

}

@media screen and (max-width: 625px) {
  footer {
    height: auto;

    .container {
      flex-direction: column-reverse;

      .contacts {
        .title {
          margin-bottom: 10px;
        }

        width: 80%;
        padding-top: 20px;
        padding-left: 0;
        margin-left: 0;
        margin-bottom: 10px;
        border-left: none;
        border-bottom: 1px dashed darken($white_color, 70%);

        .contact-items {
          justify-content: space-between;
          margin-bottom: 20px;
        }
      }

      .copyrights {
        margin-top: 5px;
        line-height: 14px;
        margin-bottom: 20px;
      }
    }
  }
}

</style>
