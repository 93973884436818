<template>
  <div>{{ show }}</div>
</template>

<script>
  export default {
    name: 'PageLicenseView',
    data () {
      // eslint-disable-next-line no-console
      console.log('LIC V:', this.$route.params);
      return {
        show: this.$route.params.name
      }
    }
  }
</script>

<style scoped>

</style>
