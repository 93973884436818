<template>
  <div v-if="!success">
    <div class="form-submit-error">
      <div v-if="error">{{ error }}</div>
    </div>

    <vue-form class="form form__no-space-top" :state="formstate" @submit.prevent="onSubmit">
      <ul>

        <validate tag="li">
          <label>Email <span class="required">*</span></label>
          <input v-model="model.username" type="email" :server-errors="{name: 'email', errors}" required
                 name="username"/>

          <field-messages class="form-field-errors" name="username" show="$touched || $submitted">
            <div class="form-field-error" slot="required">{{ $t('requiredField') }}</div>
            <div class="form-field-error" slot="email">{{ $t('wrongMail') }}</div>
            <div class="form-field-error" slot="server-errors">{{ serverErrorMessage('email') }}</div>
          </field-messages>
        </validate>

        <validate tag="li">
          <label>{{ $t('pass') }}</label>
          <input v-model="model.password" type="password" minlength="6" :server-errors="{name: 'password', errors}"
                 name="password"/>
          <field-messages class="form-field-errors" name="password" show="$touched || $submitted">
            <div class="form-field-error" slot="minlength">6 {{ $t('charsMin') }}</div>
            <div class="form-field-error" slot="server-errors">{{ serverErrorMessage('password') }}</div>
          </field-messages>
        </validate>

        <li>
          <submit-button :loading="loading" :show-check-box="true" :caption="$t('signUp')"></submit-button>
        </li>
      </ul>
    </vue-form>
  </div>
  <success :message="`${$t('welcome')} DKDHUB!`" v-else>
    <div class="text-center msg-box">{{ $t('message') }}</div>
  </success>
</template>

<script>
import {fetchApi} from '../../shared/api'
import FormWihServerErrorsMixin from './FormWihServerErrorsMixin'
import SubmitButton from './SubmitButton'
import Success from './Success'
import messages from "@/i18n";

export default {
  name: 'Register',
  components: {SubmitButton, Success},
  mixins: [
    FormWihServerErrorsMixin
  ],
  i18n: {
    messages: {
      en: {
        pass: "Password",
        signUp: "Sign up",
        requiredField: "This is a required field",
        wrongMail: "Wrong email",
        charsMin: "characters minimum",
        welcome: "Welcome to",
        message: "Please, check your email for activation",
      },
      bg: {
        pass: "Парола",
        signUp: "Регистрирай се",
        requiredField: "Това е задължително поле",
        wrongMail: "Грешен имеил",
        charsMin: "знака минимум",
        welcome: "Добре дошли в",
        message: "Моля, проверете имейла си за активиране"
      },
      ru: {
        pass: "Пароль",
        signUp: "Регистрация",
        requiredField: "Это поле обязательно для заполнения",
        wrongMail: "Ошибочный email",
        charsMin: "знаков минимум",
        welcome: "Добро пожаловать в",
        message: "Пожалуйста, проверьте электронную почту для активации"
      },
      uk: {
        pass: "Пароль",
        signUp: "Реєстрація",
        requiredField: "Це поле обовʼязкове до заповнення",
        wrongMail: "Помилковий email",
        charsMin: "знаків мінімум",
        welcome: "Вітаємо у",
        message: "Будь ласка, перевірте свою електронну пошту на наявність активації"
      },
    },
    sharedMessages: messages
  },
  props: [
    'setGlobalState',
    'globalState'
  ],

  data() {
    return {
      formstate: {},
      model: {
        username: this.globalState.username || '',
        password: ''
      },
      success: false,
      loading: false
    }
  },

  watch: {
    'model.username'(val) {
      this.setGlobalState({username: val})
    }
  },

  methods: {
    async onSubmit() {
      await this.resetErrors()
      if (this.formstate.$invalid) {
        return
      }
      this.loading = true
      const data = {
        email: this.model.username,
        password: this.model.password
      }
      try {
        await fetchApi('user/reg', data, 'post')
        this.success = true
      } catch (e) {
        this.prepareErrors(e)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
.msg-box {
  margin-bottom: 25%;
}
</style>
