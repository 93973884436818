import Vue from 'vue'
import Router from 'vue-router'
import AppMain from '../components/AppMain'
import LoggedIn from '../components/auth/LoggedIn'
import {Auth, routes as authRoutes} from '../components/auth'
import PageNotFound from '../components/PageNotFound'
import PageLicenseView from '../components/PageLicenseView'

Vue.use(Router);

export const RETAILER_BOARD_URL = '/rboard';

export default new Router({
  routes: [
    {
      path: '/',
      name: 'AppMain',
      component: AppMain
    },
    {
      path: '/auth',
      name: 'login',
      component: Auth,
      children: authRoutes
    },
    {
      path: '/licenses',
      redirect: '/licenses/list-of-available-licenses'
    },
    {
      path: '/licenses/:name',
      name: 'licenses',
      component: PageLicenseView
    },
    {
      path: '/logged-in',
      name: 'loggedIn',
      component: LoggedIn
    },
    {
      path: '*',
      component: PageNotFound
    }
  ]
})
