<template>
  <div>
    <div v-if=showCheckBox class="left-side">
      <input type="checkbox" id="tnc" name="terms" v-model="agree">
      <label for="tnc" class="small"> {{ $t('eulaP1') }}
        <a :href="tncURL" target="_blank">{{ $t('eulaP2') }}</a>
      </label>
    </div>
    <input type="submit" :disabled="(loading || (showCheckBox && !agree))" :class="{loading}" :value="caption"/>
  </div>
</template>

<script>
import LoadIndicator from '../LoadIndicator'
import {RETAILER_BOARD_URL} from "../../router";
import messages from "@/i18n";

export default {
  name: 'SubmitButton',
  // eslint-disable-next-line vue/no-unused-components
  components: {LoadIndicator},
  i18n: {
    messages: {
      en: {
        eulaP1: "I read",
        eulaP2: "terms of the service"
      },
      bg: {
        eulaP1: "Прочетох",
        eulaP2: "условията на услугата"
      },
      ru: {
        eulaP1: "Ознакомлен с",
        eulaP2: "условиями предоставления услуги"
      },
      uk: {
        eulaP1: "Ознайомлений з",
        eulaP2: "умовами надання сервісу"
      }
    },
    sharedMessages: messages
  },
  props: {
    caption: {type: String},
    loading: {type: Boolean},
    showCheckBox: {type: Boolean, default: false}
  },
  data() {
    return {
      agree: null,
      tncURL: RETAILER_BOARD_URL + '/#/legals/terms-and-conditions'
    }
  }
}
</script>

<style lang="scss" scoped>
$background: #737578;
$mtt: 40px;

.left-side {
  text-decoration: none;
  font-size: smaller;
  float: left;
  margin-top: $mtt;
  padding: 4px 4px 8px 2px;
  margin-right: 19px;
  align-self: auto;
}

input[type=submit], input[type=button] {
  background: $background;
  padding: 8px 20px 8px 20px;
  border: none;
  color: #fff;
  margin-top: $mtt;
  float: right;
  font-size: 14px;
  outline: 0;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  cursor: pointer;

  &:hover {
    background: darken($background, 20%);
    box-shadow: none;
  }

  &:active {
    background: $background;
  }

  &:disabled {
    background: rgba($background, 0.2);
  }

  &.loading {
    opacity: 0.7;
    $lightColor: #E9C9AF;
    background: linear-gradient(0, $lightColor, darken($background, 20%), $lightColor);
    background-size: 400% 400%;
    animation: gradient 4s ease infinite;
    cursor: default;
  }
}

@keyframes gradient {
  50% {
    background-position: 0 100%;
  }
}

</style>
