<template>
  <div class="error-message">
    <div v-if="message" class="error-message-title">{{ message }}</div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'Error',
  props: ['message']
}
</script>

<style lang="scss" scoped>
.error-message {
  margin-top: 30px;
  @at-root {
    &-title {
      font-size: 18px;
      text-align: center;
      font-weight: bold;
      margin-bottom: 30px;
      color: red;
    }
  }
}
</style>
